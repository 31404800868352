import MenuSpy from 'menuspy'

export default class NavSpy {

    constructor () {
        var elm = document.querySelector('.c-service-nav');
        var ms = new MenuSpy(elm, {
            activeClass: 'is-active'
        });
    }
}
