import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class StickyHeader {

    constructor (element) {
        this.element = document.querySelector(element)

        this.init()
    }

    init() {
        gsap.config({ nullTargetWarn: false });
        gsap.registerPlugin(ScrollTrigger);

        if(this.element != null) {
            ScrollTrigger.create({
                trigger: this.element,
                start: () => `+=${this.element.clientHeight}`,
                end: () => `+=${document.body.scrollHeight}`,
                toggleActions: 'play reverse none reverse',
                toggleClass: {targets: document.body, className: 'has-sticky-header'},
                // markers: true
            })
        }
    }
}
