document.documentElement.classList.remove('no-js')

import StickyHeader from './components/StickyHeader'
import MainNav from './components/MainNav'
import HomeCarousel from './components/HomeCarousel'
// import TrailingCursor from './components/TrailingCursor'
import Marquee from './components/Marquee'
import InputMask from './components/InputMask'
import NavSpy from './components/NavSpy'
import Parallax from './components/Parallax'
import SmoothScroll from 'smooth-scroll'
import ViewMore from './components/ViewMore'

const stickyHeader = new StickyHeader('[data-sticky-header]')

const mainNav = new MainNav('[data-main-nav]')

// const trailingCursor = new TrailingCursor(document.querySelector('.c-cursor'))

const homeCarousel = new HomeCarousel(document.querySelector('[data-home-carousel]'))

const marquee = new Marquee(document.querySelector('.c-marquee'))

const inputMask = new InputMask()

const scroll = new SmoothScroll('[js-scroll-to]')

const navSpy = new NavSpy()

const parallax = new Parallax()

const portfolioViewMore = new ViewMore(document.querySelector('[data-view-more-portfolio]'))
