import Swiper, { Autoplay } from 'swiper'
import 'swiper/css'

export default class Marquee {

    constructor (element) {

        Swiper.use([Autoplay])

        let swiper = new Swiper(element, {
            spaceBetween: 0,
            centeredSlides: true,
            speed: 12000,
            autoplay: {
              delay: 1,
            },
            loop: true,
            slidesPerView: 'auto',
            allowTouchMove: false,
            disableOnInteraction: true
        })
    }
}
