import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export default class HomeCarousel {

    constructor (element) {

        Swiper.use([Navigation, Autoplay])

        let swiper = new Swiper(element, {
            autoplay: {
                delay: 3000
            },
            speed: 600,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            centeredSlides: true,
            centeredSlidesBounds: true
        });
    }
}
