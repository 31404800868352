import AutoBind from 'auto-bind'
import Toggle from 'toggle-all'
import { lock, unlock } from 'tua-body-scroll-lock'
import SmoothScroll from 'smooth-scroll'

let navItems
let timeline
let anchors
let smoothScroll

export default class MainNav {

    constructor (element) {
        this.element = document.querySelector(element)

        AutoBind(this)

        this.init()
    }

    init() {
        const toggleNav = new Toggle({
            selectorToggle: '[data-toggle-nav]',
            toggleActiveClass: 'is-active',
            callbackToggle: this.onToggle
        })

        this.smoothScroll = new SmoothScroll()

        this.navItems = this.element.querySelectorAll('[data-main-nav-item] span')

        this.handleAnchors()
    }

    onToggle(el) {
        if (el.classList.contains('is-active')) {
           // Menu is closed
           unlock(this.element)
        } else {
            // Menu is opened
            lock(this.element)
        }
    }

    handleAnchors() {
        this.anchors = this.element.querySelectorAll('[data-anchor-link]')

        Array.from(this.anchors).forEach((item) => {
            item.addEventListener('click', ev => {
                if (document.body.classList.contains('services-template')) {
                    var anchor = document.querySelector('#'+ev.currentTarget.hash.substr(1))
                    this.smoothScroll.animateScroll(anchor)
                } else {
                    window.location.assign(ev.currentTarget.href)
                }
            })
        })
    }
}
