import AutoBind from 'auto-bind'

let element
let blocks
let button

export default class ViewMore {

    constructor (element) {

        AutoBind(this)

        this.element = element

        if(this.element != null) {
            this.init()
        }
    }

    init() {
        this.blocks = Array.from(this.element.querySelectorAll('[data-view-more-item]'))
        this.button = document.querySelector('[data-view-more-btn]')

        this.blocks.forEach((item, index) => {
            if(index != 0) {
                item.classList.toggle('is-hidden')
            }
        })

        this.button.addEventListener('click', this.collapse)
    }

    collapse(event) {
        event.preventDefault()

        for (let i = 0, len = this.blocks.length; i < len; i++) {
            if(this.blocks[i].classList.contains('is-hidden')) {
                this.blocks[i].classList.toggle('is-hidden')
                this.removeButton()
                return
            }
        }
    }

    removeButton() {
        if(document.querySelectorAll('[data-view-more-item].is-hidden').length == 0) {
            this.button.parentNode.removeChild(this.button)
        }
    }
}
