import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Parallax {

    constructor () {

        this.init()
    }

    init () {
        gsap.config({ nullTargetWarn: false });
        ScrollTrigger.config({ nullTargetWarn: false });
        gsap.registerPlugin(ScrollTrigger);

        // Generic fade in from bottom to top
        gsap.utils.toArray('[data-scroll=generic]').forEach((el, i) => {
            gsap.from(el, {
                y: 100,
                opacity: 0,
                duration: 1,
                ease: 'power2.out',
                scrollTrigger: {
                    trigger: el,
                    end: '+=200',
                },
            });
        });

        // Generic fade with opacity only
        gsap.utils.toArray('[data-scroll=opacity]').forEach((el, i) => {
            gsap.from(el, {
                opacity: 0,
                duration: 1,
                ease: 'power2.out',
                scrollTrigger: {
                    trigger: el,
                    end: '+=200',
                },
            });
        });

        // Image reveal from bottom to top
        gsap.utils.toArray('[data-scroll=reveal]').forEach((el, i) => {

            gsap.from(el, {
                clipPath: 'inset(100% 0 0 0)',
                duration: 1.2,
                delay: 0.8,
                ease: 'power2.out',
                scrollTrigger: {
                    trigger: el,
                    end: '+=200',
                },
            });
        });

        // Stagger animations generic fade in from bottom to top
        ScrollTrigger.batch('[data-scroll=stagger-generic] [data-scroll-item]', {
            onEnter: elements => {
                gsap.from(elements, {
                    y: 100,
                    opacity: 0,
                    duration: 0.5,
                    stagger: 0.15,
                    ease: 'power2.out'
                });
            },
            once: true
        });

        // Stagger animations with opacity only
        ScrollTrigger.batch('[data-scroll=stagger-opacity] [data-scroll-item]', {
            onEnter: elements => {
                gsap.from(elements, {
                    opacity: 0,
                    duration: 0.5,
                    stagger: 0.15,
                    ease: 'power2.out'
                });
            },
            once: true
        });

        // Stagger animations for reveal effect
        ScrollTrigger.batch('[data-scroll=stagger-reveal] [data-scroll-item]', {
            onEnter: elements => {
                gsap.from(elements, {
                    clipPath: 'inset(100% 0 0 0)',
                    duration: 1.2,
                    delay: 0.8,
                    stagger: 0.15,
                    ease: 'power2.out'
                });
            },
            once: true
        });

        // =============================================================================
        // Custom animations
        // =============================================================================

        // Pink circle
        // gsap.set('[data-scroll=custom-circle]', { yPercent: 20});

        // gsap.to('[data-scroll=custom-circle]', {
        //     yPercent: -20,
        //     ease: 'power2.out',
        //     scrollTrigger: {
        //     trigger: '.c-transformation',
        //     scrub: 1
        //     },
        // });

        // Blue square
        // gsap.set('[data-scroll=custom-square]', { xPercent: -100});

        // gsap.to('[data-scroll=custom-square]', {
        //     xPercent: 0,
        //     ease: 'power2.out',
        //     duration: 0.8,
        //     scrollTrigger: {
        //     trigger: '.c-header',
        //     },
        // });

        // Header image
        // gsap.set('[data-scroll=custom-image]', { xPercent: 100});

        // gsap.to('[data-scroll=custom-image]', {
        //     xPercent: 0,
        //     ease: 'power2.out',
        //     duration: 0.8,
        //     scrollTrigger: {
        //     trigger: '.c-header',
        //     },
        // });

        // Sticky Button
        // let nav = document.querySelector('.c-header__nav');

        // if( nav != null) {
        //     ScrollTrigger.create({
        //         trigger: nav,
        //         start: () => `+=${nav.clientHeight}`,
        //         end: () => `+=${document.body.clientHeight}`,
        //         toggleActions: 'play reverse none reverse',
        //         toggleClass: {targets: nav, className: 'is-hidden'},
        //         // markers: true
        //     });
        // }
    }
}
